<template>
  <div class="mychat_div">
    <div class="mychat_ln">
        <div class="my_ln_1" v-if="!isMobile">
            <el-page-header @back="goBack" content="历史对话">
            </el-page-header>
            <!-- <div class="button" >
                <router-link class="router" :to="{path:'/index'}">
                <i class="el-icon-arrow-left"></i>主页
                </router-link>
            </div> -->
        </div>
        <div class="right">

            <div class="my_ln_2" v-for="savelist in savelists" :key="savelist.id">
                
                <div class="collapse-item" >
                 
                    <div class="collapse-title" >
                        <!-- <p class="collapsetitle">{{ history.title.substring(0, 15) + (15 <   history.title.length ? '...' : '') }}</p> -->
                        
                        <span class="collapsetitle">{{ savelist.title}}</span>
                
                        <div class="collapse-summary" >
                        
                        <!-- {{ history.summary.substring(0, 15) + (15 <  history.summary.length ? '...' : '') }} -->
                            {{ savelist.abstract}}
                        
                        </div> 
                        
                        
                        
                    


                    </div>
                    
                    <div class="dialogList" ref="dialogHeight" v-for="(item,index) in dialog" :key="index"  v-if="isDialogOpen&&selectedDialogIndex === savelist.did">
                    
                        <div class="list_top" v-if="index % 2 === 0">
                            <div class="name">{{account.username['0']}}</div>
                            <!-- <div class="text">{{item}}</div> -->
                            <mavon-editor
                                v-if="item"
                                class="md_box"
                                    :value="item"
                                    :subfield="prop.subfield" 
                                    :defaultOpen="prop.defaultOpen"
                                    :toolbarsFlag="prop.toolbarsFlag"
                                    :editable="prop.editable"
                                    :scrollStyle="prop.scrollStyle"
                            >
                            </mavon-editor>
                        
                        </div>
                        <div class="list_bottom" v-else>
                            
                            <div class="name">AI</div>
                            <mavon-editor
                                v-if="item"
                                class="md_box"
                                    :value="item"
                                    :subfield="prop.subfield" 
                                    :defaultOpen="prop.defaultOpen"
                                    :toolbarsFlag="prop.toolbarsFlag"
                                    :editable="prop.editable"
                                    :scrollStyle="prop.scrollStyle"
                            >
                            </mavon-editor>
                            <!-- <div class="text">{{item}}</div> -->
                        </div>
                        
                    </div>

                     <el-button type="text" @click="toggleDialog(savelist.did)">
                        <!-- {{showAll}} -->
                        {{isDialogOpen&&selectedDialogIndex === savelist.did?'收起':'展开'}}
                        <i :class="isDialogOpen&&selectedDialogIndex === savelist.did ? 'el-icon-arrow-up ': 'el-icon-arrow-down'"></i>

                    </el-button>
                </div>
                

                <div  class="collapse-button"  >
                    <span class="collapse-time">发布时间：{{savelist.date}}</span>
                    <el-button type="text" @click="delDialog(savelist.did)">删除</el-button>
                </div>
           


            </div>

            <div v-if="!savelists.length">
                <span>无保存记录,快去和AI对话吧！</span>
            </div>

        </div>
        
    </div>
  

   

   
  </div>

  
</template>

<script>
import Swiper from 'swiper';
import { payAccountcheck,engineDeldialog,engineGetdialoglist,engineGetdialog} from "@/api/user";
import { mavonEditor } from 'mavon-editor'

export default {
  components:{// 在页面中注册组件
        mavonEditor 
    },
  data() {
   
    return {
        savelists:{},
        dialog:{},
        isDialogOpen:false,
        selectedDialogIndex:-1,
       

    //    activeNames: ['1','2'],
    //   historyCollapsed:true,
        isMobile:"",
       
     
    };
  },
 
  mounted() {
    var that = this;
    that.isMobile=navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i);
    that.getEngineGetdialoglist();
    that.getPayAccountcheck();
    // that.getEngineGetdialog();
    // that.$nextTick(function() {
    //     var dialogBoxHeght = that.$refs.dialogHeight.offsetHeight;
    //     console.log("height:",height)
	// 		// that.closeDialog();
	// });
  },
  computed:{
     prop () {
      let data = {
        subfield: false,// 单双栏模式
        defaultOpen: 'preview',//edit： 默认展示编辑区域 ， preview： 默认展示预览区域 
        editable: false,    // 是否允许编辑
        toolbarsFlag: false,
        scrollStyle: true
      }
      return data
    }
  },
  methods: {

    //获取历史对话列表
   getEngineGetdialoglist(){
        var that = this;
        engineGetdialoglist().then((res) => {
            if(res.code==0){
                that.savelists=res.result.savelist;
                
            }
        });
    },

    // 展开对话
    async toggleDialog(index) {
      var that = this;
      if (index === that.selectedDialogIndex&&that.isDialogOpen) {
        that.selectedDialogIndex = -1;
        return;
      }else{
        console.log("1:",that.isDialogOpen)
        that.selectedDialogIndex = index;
        that.isDialogOpen = false
        that.getEngineGetdialog(index);
        

      }

      
      
    },

    getEngineGetdialog(did){
        var that = this;
        var param={
                    "did":did,
                    
                }
        
        engineGetdialog(param).then((res) => {
            if(res.code==0){
                that.dialog=res.result.dialog;
                
                that.selectedDialogIndex = res.result.did
                that.isDialogOpen = true
                
               
            }
        });
        
    },
    // 删除对话

    delDialog(did){
        var that = this;
        that.$confirm('此操作将永久删除该记录, 是否继续?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
            engineDeldialog({"did":did}).then(res=>{
                if(res.code===0){
                   // 从数组中删除该对话
                   that.savelists = that.savelists.filter(item => item.did !== did);
                   that.$message.success("删除成功");
                
                }else {
                    // 如果删除失败，弹出一个提示框显示错误信息
                 that.$message.error("删除失败");
                 }
        
        })
        .catch(() => {
          this.$message.info('数据操作失败，请重试！');          
        });
      })
    },

            
   
   

     //获取用户信息
   getPayAccountcheck(){
        var that = this;
        payAccountcheck().then((res) => {
            if(res.code==0){
                that.account=res.result;
            }
        });
    },

    goBack(){
         var that = this;
        that.$router.push({ path: "/index" });
    },

   

   



   
   
  },
};
</script>
<style lang="less" scoped>

  
.el-collapse /deep/.el-collapse-item__header {
    // font-size: 18px;
    // color: #bb1414;
    // display: flex;
    // background-color: antiquewhite;
    // height: 100px;
    min-height: 115px;
    line-height: 30px;
    display: flex;
    align-items: flex-start;
}


.el-collapse /deep/ .el-collapse-item__arrow {
//   flex: 1 0 auto;
//   order: -1;
//   color: red;
  margin-top: 28px;
}


// .el-collapse /deep/ .el-collapse-item__content.is-active {
//   display: none;
//   animation: fadeOut 0.3s forwards;
// }

// .el-collapse /deep/ .el-collapse-item__title.is-active {
//   position: relative;
//   padding-left: 32px;
// }

// .el-collapse /deep/ .el-collapse-item__title i.el-icon-arrow-right {
//   position: absolute;
//   left: 0;
//   top: 50%;
//   margin-top: -6px;
//   transition: transform 0.3s;
//   transform: rotate(0deg);
// }

// .el-collapse /deep/ .el-collapse-item__title.is-active i.el-icon-arrow-right {
//   transform: rotate(90deg);
// }

</style>

